.contenedor-preguntas-respuestas
    margin: 0 3% 5em 8%
    font-size: 0.9em
    color: $primario
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(1, 1fr)
    grid-gap: 2em 10%

    @media screen and ( max-width: 850px )
        width: 90%
        grid-template-columns: repeat(1, 0fr)
        grid-template-rows: repeat(1)
        font-size: 0.95em
        grid-gap: 0

    @media screen and ( min-width: 1500px )
        font-size: 1.2em

    @media screen and ( min-width: 1850px )
        font-size: 1.25vw

    .preguntas
        border-bottom: $linea-pregunta 0.12em solid
        padding-top: 1.5em
        width: 85%
        font-size: 1.2em

        .pregunta
            width: 100%
            cursor: pointer
            span
                width: 100%

        .respuesta
            font-size: 0.7em
            margin: 1.5em 0
            color: $primario
            font-weight: 50
            overflow: hidden
            transition: 1s ease-out
            
            .link-preguntas
                color: $primario

        @media screen and ( max-width: 850px )
            width: 92%
            margin: 4%


    button
        background: none
        border: none
        
        img
            width: 1.5em
            height: 1.5em
            transition: 0.5s all ease
            cursor: pointer