.cls-1
    fill:#3d5972

.cls-2
    fill:#82bcbb

.cls-3
    fill:#c01250

.cls-4
    fill:#75b6b5

.cls-5
    fill:#edbe9e

.cls-6
    fill:#77b6b5

.cls-7
    fill:#c01351

.cls-8
    fill:#3c5972

.cls-9
    fill:#ecbe9f

.cls-10
    fill:#425c75

.cls-11
    fill:#c11150

.cls-12
    fill:#ebab7f

.cls-13
    fill:#76b6b5

.cls-14
    fill:#3f5c74

.cls-15
    fill:#92b4b8

.animacion-personajes
    width: 100%
    height: 100%
    position: absolute

.bombillo-1
    animation: bombilloUno 7s ease-in-out infinite
    transform-origin: center

    @keyframes bombilloUno
        0% 
            transform: translateY(0)
            fill: $primario
        
        1%
            transform: translateY(1vw)
        
        2%
            transform: translateY(-1vw)
        
        3%
            transform: translateY(1vw)
        
        4%
            transform: translateY(-1vw)
        
        5%
            transform: translateY(1vw)
        
        6%
            transform: translateY(-1vw)
        
        7%
            transform: translateY(1vw)
        
        8%
            transform: translateY(-1vw)
        
        15%
            fill: $primario
        
        17%
            fill: $secundario
        
        19%
            fill: $primario
        21%
            fill: $secundario
        
        23%
            fill: $primario

        25%
            fill: $secundario
        
        60%
            fill: $secundario
        
        100% 
            fill: $primario


.onda-1
    animation: ondaUno 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 2s

    @keyframes ondaUno
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.onda-2
    animation: ondaUno 2.8s ease-in-out infinite
    transform-origin: center
    animation-delay: 1s

    @keyframes ondaDos
        0%
            opacity: 1
        
        50%
            opacity: 0
        
        100%
            opacity: 1
    


.onda-3
    animation: ondaUno 2.8s ease-in-out infinite
    transform-origin: center


    @keyframes ondaTres
        0%
            opacity: 1
        
        50%
            opacity: 0
        
        100%
            opacity: 1

.billete-1
    animation: billeteUno 12s ease-in-out infinite
    transform-origin: center

    @keyframes billeteUno
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(-3%) rotateZ(4deg) rotateY(0deg) rotateX(20deg)
        
        50% 
            transform: translateX(-4%) rotateZ(5deg) rotateY(0deg) rotateX(30deg)
        
        75% 
            transform: translateX(0%) rotateZ(0deg) rotateY(0deg) rotateX(40deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)