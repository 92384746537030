.contenedor-seccion-wwd
    margin-top: 7em
    
    @media screen and ( min-width: 1500px )
        font-size: 1.18em

    @media screen and ( min-width: 1800px )
        font-size: 1.25vw

.what-we-do
    color: #fff
    width: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    position: relative
    background-position: center

    .fondo-ilustracion
        width: 100%
        height: 100%

    .ilustracion
        width: 100%
        position: relative
        
    .tarjeta-hover
        width: 8.5em
        height: 1.2em
        padding: 1.8%
        font-size: 1.5em
        position: absolute
        @media screen and ( max-width: 850px )
            width: 12em
            height: 2em
            font-size: 1.2em

    .tarjeta-earn
        top: 8.5vw
        left: 59%
        border-radius: 1.2em 0.23em 1.2em 1.2em
        transition: 0.5s ease-in-out

        &:hover
            background: $secundario
            
        &:hover ~ .animacion-earn
            width: 10em
            opacity: 1

        @media screen and ( max-width: 850px )
            top: 38em
            left: 41%
            border-radius: 0.3em 2em 2em 2em


    .tarjeta-transparente-earn
        top: 8.5vw
        left: 59%
        border-radius: 1.2em 0.23em 12em 1.2em
        transition: 0.5s ease-in-out
        z-index: 100
            
        &:hover ~ .animacion-earn
            width: 10em
            padding: 0.5em 2%
            opacity: 1

        &:hover ~ .tarjeta-earn
            background-color: $secundario
        
        @media screen and ( max-width: 850px )
            top: 38em
            left: 41%
            border-radius: 0.3em 2em 2em 2em

    .tarjeta-create
        top: 17vw
        left: 19%
        border-radius: 0.23em 1.2em 1.2em 1.2em
        transition: 0.5s ease-in-out

        &:hover
            background: $secundario

        @media screen and ( max-width: 850px )
            top: 3em
            left: 37%
            border-radius: 0.3em 2em 2em 2em

    .tarjeta-transparente-create
        top: 17vw
        left: 19%
        border-radius: 0.23em 1.2em 1.2em 1.2em
        transition: 0.5s ease-in-out
        z-index: 100

        &:hover ~ .animacion-create
            width: 18em
            padding: 0.5em 2%
            opacity: 1

        &:hover ~ .tarjeta-create
            background-color: $secundario

        @media screen and ( max-width: 850px )
            top: 3em
            left: 37%
            border-radius: 0.3em 2em 2em 2em
        
    .tarjeta-share
        top: 41vw
        left: 41%
        border-radius: 1.2em 1.2em 0.23em 1.2em
        transition: 0.5s ease-in-out

        &:hover
            background: $secundario

        @media screen and ( max-width: 850px )
            top: 18.8em
            left: 23%
            border-radius: 2em 0.3em 2em 2em

    .tarjeta-transparente-share
        top: 41vw
        left: 41%
        border-radius: 1.2em 1.2em 0.23em 1.2em
        transition: 0.5s ease-in-out
        z-index: 100

        &:hover ~ .animacion-share
            width: 14em
            padding: 0.5em 2%
            opacity: 1

        &:hover ~ .tarjeta-share
            background-color: $secundario

        @media screen and ( max-width: 850px )
            top: 18.8em
            left: 23%
            border-radius: 2em 0.3em 2em 2em

    .tarjeta-animacion
        transition: 0.8s ease-in-out
        opacity: 0
        border-radius: 2em
        position: absolute
        font-size: 0.8em
        height: auto
        width: 0
        padding: 0.5em 0
        overflow: hidden
    
    .animacion-earn
        top: 22.5em
        left: 58em
        height: 3.8em
        .animacion
            overflow: hidden
            width: 10em
            text-align: center
            padding: 0.15em

        @media screen and ( max-width: 850px )
            top: 56.5em
            left: 43%
            padding: 0.5em 0
            font-size: 0.9em

            .animacion
                width: 17em
        
    .animacion-create
        top: 29.5em
        left: 20em
        .animacion
            overflow: hidden
            width: 18em
            text-align: center
            padding: 0.35em

        @media screen and ( max-width: 850px )
            top: 9.5em
            left: 41%
            padding: 0.5em 2%
            font-size: 0.9em

            .animacion
                width: 16.5em

    .animacion-share
        top: 34.5em
        left: 58%
        .animacion
            overflow: hidden
            width: 13em
            text-align: center
            padding: 0.2em

        @media screen and ( max-width: 850px )
            top: 31em
            left: 10%
            padding: 0.5em 3%
            font-size: 0.9em

            .animacion
                width: 17em
            
    @media screen and ( max-width: 850px )
        margin-bottom: 3em

        .tarjeta-visible
            opacity: 1
            width: 17em
            height: auto
        
        .tarjeta-transparente
            background: $secundario

.boton-iniciar
    position: absolute
    margin-right: 6%
    bottom: 1.5em
    right: 0
    z-index: 10
    background: $secundario
    border: none
    width: 13em
    height: 2.5em
    font-size: 1.2em
    border-radius: 2em
    color: white
    cursor: pointer

    @media screen and ( max-width: 850px )
        position: relative
        font-size: 1em
        height: 2em
        top: 1em
        left: 28%