.contenedor-carrusel
    margin: 3em 0 1em
    width: 100%

.carrusel-tours
    overflow: hidden
    position: relative

    .contenedor-item
        width: 40vw
        margin: 0 2vw

        .tour-item
            width: 100%
            height: 20vw
            position: relative
            border-radius: 1.7em
            border-bottom-left-radius: 0.2em
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: cover

            .nombre-precio
                width: 100%
                height: 100%
                position: absolute
                top: 0
                left: 0
                background: rgba(0,0,0, 0)
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 80%, rgba(44,34,80, 0.8) 100%)

                .nombre
                    width: 86%
                    margin: 0 7% 0.7em
                    font-size: 1.5em
                    font-weight: 500

                .precio
                    padding: 0.8em 1.6em
                    font-size: 1.1em
                    background-color: $secundario
                    border-bottom-right-radius: 1.4em
                    border-top-left-radius: 1.4em

                .foto-tourmaker
                    position: absolute
                    top: 1em
                    right: 1em
                    width: 3.7em
                    height: 3.7em
                    border-radius: 100%
                    border: solid 0.15em white

        .descripcion-tour
            width: 100%
            padding: 2em 0 1em
            height: 6em
            overflow: hidden
            transition: 0.5s all ease

            img
                width: 15%

            .descripcion
                text-overflow: ellipsis
                width: 100%

    
    .botonces-carrusel
        position: absolute
        bottom: 2.5em
        left: 22%
        width: 58%

        .flecha
            width: 3em
            height: 3em
            background: none
            border: none
            margin: 1em
            cursor: pointer
            outline: none

            div
                width: 1em
                height: 1em
                border-bottom-width: 0.4em
                border-left-width: 0.4em
                transform: rotate(45deg)
                border-bottom: solid 0.4em $secundario
                border-left: solid 0.4em $secundario

            .flecha-derecha
                border: none
                border-top: solid 0.4em $secundario
                border-right: solid 0.4em $secundario

    @media screen and ( max-width: 800px )

        .contenedor-item
            width: 75vw
            margin: 0 2vw

            .tour-item
                height: 40vw

            .descripcion-tour
                text-align: center

        .botonces-carrusel
            bottom: 2.5em
            left: 3%
            width: 94%