.contenedor-crear-tour
    display: flex
    flex-direction: row

    @media screen and ( max-width: 850px )
        width: 100vw
        display: flex
        flex-direction: column

    @media screen and ( min-width: 1500px )
        font-size: 1.2em

    @media screen and ( min-width: 1800px )
        font-size: 1.2vw

    .textos
        margin-top: 5vw
        width: 46%
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start

        h2
            color: $secundario
            margin-left: 15%
            font-size: 1.8em

        p 
            margin: 2.5em 15%
            font-size: 0.89em

        .boton-correo
            margin: 1em 14%
            border: none
            width: 83%
            height: 3.5em
            border-radius: 1em 0.23em 1em 1em
            font-size: 1.2em
            cursor: pointer
            &:hover
                background: $secundario

        @media screen and ( max-width: 850px )
            margin-top: 3em
            width: 100vw
            text-align: center

            h2
                margin-left: 13%
                font-size: 1.2em
                width: 75%

            p 
                margin: 1em 2em
                font-size: 1em

            .boton-correo
                margin: 1em 24%
                border: none
                width: 20em
                height: 4em
                border-radius: 1em 0.23em 1em 1em
                font-size: 0.9em

    .imagen
        width: 45%

        img
            width: 100%
            height: 100%
            object-fit: contain
            
        @media screen and ( max-width: 850px )
            width: 100%

    form
        margin: 0.5em 14% 3em 14%
        width: 87%

        .asterisco
            color: $secundario

        .texto-form 
            display: flex
            margin-bottom: .2em
        .texto-formulario
            font-size: 0.89em
            font-weight: 600

        input
            border-radius: 2em
            width: 72%
            height: 1.8em
            margin-top: 0.5em
            padding: 0 1em

        .boton-formulario
            border-radius: 2em
            width: 13%
            height: 2em
            border: none
            margin-left: 4%
            cursor: pointer

        @media screen and ( max-width: 850px )
            margin: 0em 7%

            .texto-formulario
                font-size: 1em

            input
                border-radius: 2em
                width: 50%
                height: 1.3em
                margin-top: 1em
                margin-left: 5%

            .boton-formulario
                border-radius: 2em
                width: 12%
                height: 1.8em
                border: none
                margin-left: 3%
                font-size: 0.8em