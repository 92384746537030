=box-shadow($box)
    -webkit-box-shadow: $box
    -moz-box-shadow: $box
    -ms-box-shadow: $box
    box-shadow: $box
    -o-box-shadow: $box

=border-radius($radio)
    -webkit-border-radius: $radio
    -moz-border-radius: $radio
    -ms-border-radius: $radio
    border-radius: $radio
    -o-border-radius: $radio

=background-size($size)
    -webkit-background-size: $size
    -moz-background-size: $size
    -ms-background-size: $size
    -o-background-size: $size
    background-size: $size

=transform($datos)
    -webkit-transform: $datos
    -moz-transform: $datos
    -o-transform: $datos
    -ms-transform: $datos
    transform: $datos

=animation($datos)
    -webkit-animation: $datos
    -moz-animation: $datos
    -o-animation: $datos
    -ms-animation: $datos
    animation: $datos

=transition($datos)
    transition: $datos
    -moz-transition: $datos
    -webkit-transition: $datos
    -o-transition: $datos
    -ms-transition: $datos

=border-top-left-radius($radio)
    -webkit-border-top-left-radius: $radio
    -moz-border-top-left-radius: $radio
    -ms-border-top-left-radius: $radio
    border-top-left-radius: $radio
    -o-border-top-left-radius: $radio

=border-top-right-radius($radio)
    -webkit-border-top-right-radius: $radio
    -moz-border-top-right-radius: $radio
    -ms-border-top-right-radius: $radio
    border-top-right-radius: $radio
    -o-border-top-right-radius: $radio

=border-bottom-left-radius($radio)
    -webkit-border-bottom-left-radius: $radio
    -moz-border-bottom-left-radius: $radio
    -ms-border-bottom-left-radius: $radio
    border-bottom-left-radius: $radio
    -o-border-bottom-left-radius: $radio

=border-bottom-right-radius($radio)
    -webkit-border-bottom-right-radius: $radio
    -moz-border-bottom-right-radius: $radio
    -ms-border-bottom-right-radius: $radio
    border-bottom-right-radius: $radio
    -o-border-bottom-right-radius: $radio