.titulo-city-scan
    font-size: 40px
    margin-bottom: 0.3em

    .texto-city
        text-align: center
        font-size: 1em
        font-family: Ubuntu
        letter-spacing: 2px
        color: #FFFFFF
        font-weight: lighter

    .texto-scan
        text-align: center
        font-size: 1em
        font-family: Ubuntu
        letter-spacing: 2px
        color: #FFFFFF
        font-weight: bold
        

.contenedor-video-inicial
    border-bottom-left-radius: 5em
    overflow: hidden
    font-size: 16px
    position: relative
    max-width: 100vw
    height: 80vh
    min-height: 35em
    max-height: 40em

    .contenedor-img
        position: relative

        img
            width: 100%
            height: 100%
            object-fit: cover

        &:after
            content: ""
            position: absolute
            background-color: black
            opacity: 0.5
            width: 100%
            height: 100%
            top: 0
            bottom: 0
            right: 0
            left: 0


    @media screen and ( max-width: 1000px )
        .titulo-parrafo
            width: 85%
        .text-discover
            width: 85%

    @media screen and ( max-width: 600px )
        .titulo-parrafo
            width: 90%
        .text-discover
            width: 90%
        

    video
        object-fit: cover

    .gradiente
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        // background: rgba(0,0,0, 0)
        // background-color: $negro-oscuro-transparente
        background-color: $rojo-oscuro-transparente
        // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, $morado-oscuro 100%)
        // padding-bottom: 8em
        color: white

        .titulo-parrafo
            text-align: center
            font: normal normal bold 50px/57px Ubuntu
            letter-spacing: 3px
            font-size: 2.1em
            width: 28em
            max-width: 85%
            line-height: 1.2

        .text-discover
            text-align: center
            font: normal normal normal 20px/30px
            font-family: Ubuntu
            text-align: center
            letter-spacing: 2.5px
            text-transform: uppercase
            font-weight: lighter
            font-size: 1.4em
            width: 80%