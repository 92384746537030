$primario: #191B27
$secundario: #EC3B62
$terciario: #58C7DE

$morado-oscuro: rgb(44,34,80)
$morado-oscuro-transparente: rgb(44,34,80, 0.5)
$negro-oscuro-transparente: #191b2775
$rojo-oscuro-transparente: rgba(236, 59, 98, 0.1)
$linea-pregunta: #EEE1F4

$cityScan: #887AB8