.header {
    display: flex;
    width: 100%;
    background: $primario 0% 0% no-repeat padding-box;
    @include box-shadow(0px 3px 3px #00000029);
    @include border-radius(0px 0px 50px 0px);
    padding: 1em 0;
    position: fixed;
    z-index: 100;
    transition: 0.7s all ease;

    .btn-menu-responsive {
        display: none;
    }

    .user-responsive {
        display: none;
    }

    .user-pc {
        display: initial;
    }

    .logo {
        display: flex;
        align-items: center;
        width: 48%;

        .imgLogo {
            width: 40%;
            margin-left: 2em;
            cursor: pointer;

            @media screen and (max-width: 800px) {
                width: 45%;
            }
        }

        .text {
            font-size: 1.5em;
            margin: 0 15%;
            color: white;

            span {
                font-weight: bold;
            }
        }
    }

    .profile {
        cursor: pointer;
        fill: white;
        width: 30px;
    }

    .profile:hover {
        filter: invert(30%) sepia(9%) saturate(2765%) hue-rotate(149deg) brightness(94%) contrast(84%);
    }

    .profile-selected {
        width: 30px;
        color: $secundario;
    }

    .btn-start-movil {
        display: none;
    }

    .options {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 50%;
        font-size: 0.9em;
        cursor: pointer;
        letter-spacing: 2px;

        .btn-start-pc {
            background-color: $secundario;
            @include border-radius(15px);
            color: white;
            padding: 0.5em 1.2em;
        }

        .btn-start-pc:hover {
            background-color: white;
            color: black;
        }

        .nav-option {
            color: white;
            text-decoration: none;
        }

        .nav-option-selected {
            color: $secundario;
            text-decoration: none;
        }

        .nav-option:hover {
            color: $secundario;
        }

        .language {
            color: $primario;
            background-color: white;
            border: none;
            @include border-radius(40px);
            width: fit-content;
            cursor: pointer;
            display: flex;
            font-size: 0.9em;

            .flex-cont {
                display: flex;
                align-items: center;

                .bandera {
                    order: 1;
                    width: 2em;
                    height: 2em;
                    border-radius: 100%;
                }

                .bandera-en {
                    order: 2;
                }

                .language-es {
                    order: 2;
                    margin: 0;
                    padding: 0 0.7em 0 0;
                }

                .language-en {
                    order: 1;
                    margin: 0;
                    padding: 0 0 0 0.7em;
                }
            }
        }

        .language:hover {
            color: $secundario;
        }
    }
}

.header-transparente {
    background: none;
    transition: 0.4s all ease;
    box-shadow: none;
}

.header-falso {
    height: 6em;
}

.margin-header {
    margin-bottom: 1em;
}

@media screen and (max-width: 800px) {
    .header {
        width: 100vw;
        justify-content: space-between;
        align-items: center;
        padding: 0.5em;
        @include border-radius(0px 0px 15px 0px);

        .logo {
            width: 60%;
            justify-content: center;
            z-index: 30;
            order: 2;

            .imgLogo {
                width: 150px;
                margin-left: 0;
                cursor: pointer;
            }
        }

        .user-responsive {
            display: flex;
            cursor: pointer;
            width: 3em;
            height: 3em;
            position: absolute;
            top: 0;
            right: 0;
        }

        .btn-menu-responsive {
            display: block;
            position: relative;
            background: transparent;
            border: none;
            cursor: pointer;
            order: 3;
            margin-left: 1em;
            margin-right: 1em;
            z-index: 30;
            width: 20%;

            @keyframes aparecer {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }

            @keyframes rotar-arriba {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(-45deg) translate(-14.6px, 14px);
                }
            }

            @keyframes alinear-arriba {
                from {
                    transform: rotate(-45deg) translate(-14.6px, 14px);
                }
                to {
                    transform: rotate(0deg);
                }
            }

            @keyframes rotar-abajo {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(45deg) translate(2px, -20.51px);
                }
            }

            @keyframes alinear-abajo {
                from {
                    transform: rotate(45deg) translate(2px, -20.51px);
                }
                to {
                    transform: rotate(0deg);
                }
            }
            @keyframes ocultar {
                from {
                    opacity: 1;
                }
                to {
                    opacity: 0;
                }
            }
        }
        .user-pc {
            display: none;
        }

        .profile {
            width: 40px;
            height: 40px;
        }

        .btn-start-movil {
            display: initial;
            background-color: $secundario;
            @include border-radius(15px);
            color: white;
            padding: 0.4em 0.5em;
            text-align: center;
            font-size: 0.8em;
            z-index: 10;
            order: 1;
            height: fit-content;
            width: 20%;
        }

        .options {
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            background: $primario;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: ease 1s all;
            z-index: 20;

            .btn-start-pc {
                display: none;
            }

            .nav-option {
                font-size: 2em;
                margin: 0 0 1em;
            }

            .nav-option-selected {
                font-size: 2em;
                margin: 0 0 1em;
            }

            .language {
                margin-top: 1em;
                font-size: 1.5em;
            }
        }
    }
}
