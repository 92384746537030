.contenedor-opciones-descargar
    width: 100%

    .celulares
        width: 45%
        margin-right: 5%
        margin-bottom: 2em

        .celuar 
            width: 40%
            overflow: hidden

            img
                width: 100%
                object-fit: contain
                margin-bottom: 2em
                margin-left: 0
                transition: 1s all ease
                opacity: 1
                transform: translateX(0%)

            >.celular-elevado
                margin-bottom: 10em
                margin-top: 1em

            .celular-derecha
                margin-left: 100%
                transition: 1s all ease
                opacity: 0
                transform: translateX(100%)

            .celular-izquierda
                margin-left: -100%
                transition: 1s all ease
                opacity: 0
                transform: translateX(-100%)

    .contenedor-urls
        width: 55%

        .contenedor-textos
            width: 75%

            .imagen-logo
                width: 10em
                margin: 2em 1em 0.5em 0

            .titulo-parrafo
                margin: 0em 0 1em
                font-size: 2em

            .boton-descargar
                background: $secundario
                width: 100%
                margin: 1em 0 0.7em
                text-decoration: none
                padding: 0.8em 0
                border-radius: 0.8em
                border-top-left-radius: 0.2em

            .botones-tienda
                width: 100%

                img
                    width: 9em
                    margin: 0 0.5em

            .texto-tarjeta
                width: 95%
                text-align: end
                margin-right: 2%

    @media screen and ( max-width: 800px )
        flex-direction: column
        
        .celulares
            width: 100%
            justify-content: center

            .celuar
                width: 45%

        .contenedor-urls
            width: 100%

            .contenedor-textos
                width: 90%
                margin: 0 5%

                .titulo-parrafo
                    margin-top: 0

.pasos-aplicacion
    width: 100%

    .imagen-paso
        width: 25%
        padding: 2em 0

        img
            width: 65%
            margin-bottom: 4em

        .imagen-paso-1
            transform: rotate(-10deg)
        .imagen-paso-2
            transform: rotate(10deg)
        .imagen-paso-3
            transform: rotate(-5deg)

    .pasos
        width: 40%
        margin: 0 5%

        .informacion-paso
            width: 63%

        .intrucciones-paso
            width: 90%
            margin: 1em 0

            .titulo-tarjeta
                font-weight: 500
                margin: 1em 0

            .contenedor-globo
                width: 2.5em
                height: 2.5em

        .borde
            background-color: $primario

        .borde-arriba
            height: 1.8em
            width: 100%
            background-position-x: 100%
            background-s: 200% 100%
            // background-color: rgba(179, 104, 169, 0.3)
            border-bottom-left-radius: 1.5em
            // animation: cambiar 5s ease infinite
            background-size: 200% 100%

        .borde-arriba-desactivado
            background: linear-gradient(130deg, rgb(179, 104, 169), rgba(179, 104, 169, 0.3))

        .borde-arriba-activado
            background: linear-gradient(130deg, rgb(179, 104, 169, 0.3), rgb(179, 104, 169))
            animation: Animation 2s ease 1

        // .borde-arriba-animado
        //     background-color: rgba(179, 104, 169, 1)
        //     animation-name: cambiar
        //     animation-duration: 2s


        .borde-derecha
            width: 1.8em
            height: 17em
            background: linear-gradient(rgba(179, 104, 169, 0.3) 10%,rgba(136, 122, 184, 0.5) 90%)
            border-top-right-radius: 1.5em
            border-bottom-left-radius: 1.5em
        
        .borde-izquierda
            width: 1.8em
            height: 17em
            background: linear-gradient(rgba(179, 104, 169, 0.3) 10%,rgba(136, 122, 184, 0.5) 90%)
            border-top-left-radius: 1.5em
            border-bottom-right-radius: 1.5em

        .borde-abajo
            height: 1.8em
            width: 30%
            background-color: rgba(136, 122, 184, 0.5)
            // background: linear-gradient(90deg, rgba(136, 122, 184, 0.5) ,rgba(179, 104, 169, 0.3))
            border-bottom-right-radius: 1.5em

        .linea-imagen
            width: 100%

            .contenedor-globo
                width: 2.5em
                height: 2.5em

            .borde-arriba
                border-radius: 0
                border-bottom-right-radius: 1.5em

        .paso-2
            margin-top: 2em

            .borde-abajo
                border-radius: 0
                border-bottom-left-radius: 1.5em


        @media screen and ( max-width: 800px )
            width: 95%
            margin-bottom: 2em

            .imagen-paso
                display: none


            .paso-1, .paso-2, .paso-3
                align-items: flex-start
                margin-top: 0

                &> .contenedor-linea-imagen
                    width: 35%

                    img
                        width: 100%
                        object-fit: contain

                    .borde-derecha
                        margin-top: 0.6em
                        margin-left: 2em
                        height: 3em
                        +border-radius(0)
                        +border-top-left-radius(1.5em)

                    .borde-izquierda
                        margin-top: 0.6em
                        margin-right: 2em
                        height: 3em
                        +border-radius(0)
                        +border-top-right-radius(1.5em)


                .m-der
                    margin-right: 3%
                .m-izq
                    margin-left: 3%

            .paso-2
                .borde-arriba
                    margin-left: 2em
                    width: calc( 100% - 3em )
                    +border-radius(0)
                    +border-bottom-left-radius(1.5em)
                    +border-top-right-radius(1.5em)
            
            .paso-3
                .borde-arriba
                    margin-right: 2em
                    width: calc( 100% - 3em )
                    +border-radius(0)
                    +border-top-left-radius(1.5em)
                    +border-bottom-right-radius(1.5em)



            .informacion-paso
                width: 65%

                .titulo-tarjeta
                    text-align: center

                .texto-tarjeta
                    text-align: center

.titulo-seccion
    width: fit-content
    min-width: 11em
    // background: $primario
    padding: 1.2em 3em
    border-bottom-left-radius: 2.5em
    text-transform: uppercase
    font-size: 1.4em

    @media screen and ( max-width: 800px )
        width: fit-content
        font-size: 1.2em
        padding: 0.8em 2em
        

.titulo-seccion-izquierda
    border-bottom-right-radius: 2.5em
    border-bottom-left-radius: 0
    justify-content: flex-start
    

@keyframes Animation 
    0%
        background-position:10% 0%
    50%
        background-position:91% 0%
    100%
        background-position:10% 0%

    
@import ./components/preguntas/components/pregunta