.color-1
    fill:#cae0df

.color-2
    fill:#c01351

.color-3
    fill:#3c5972

.color-4
    fill:#77b6b5

.color-5
    fill:#425c75

.color-6
    fill:#c01250

.color-7
    fill:#c11150

.fondo-animacion
    width: 100%
    height: 100%

.bombillo-2
    animation: bombilloDos 7s ease-in-out infinite
    transform-origin: center
    animation-delay: 2s

    @keyframes bombilloDos
        0% 
            transform: translateY(0)
            fill: $primario
        
        1%
            transform: translateY(1vw)
        
        2%
            transform: translateY(-1vw)
        
        3%
            transform: translateY(1vw)
        
        4%
            transform: translateY(-1vw)
        
        5%
            transform: translateY(1vw)
        
        6%
            transform: translateY(-1vw)
        
        7%
            transform: translateY(1vw)
        
        8%
            transform: translateY(-1vw)
        
        15%
            fill: $primario
        
        17%
            fill: $secundario
        
        19%
            fill: $primario
        
        21%
            fill: $secundario
        
        23%
            fill: $primario
        
        25%
            fill: $secundario
        
        60%
            fill: $secundario
        
        100% 
            fill: $primario


.bombillo-3
    animation: bombilloTres 7s ease-in-out infinite
    transform-origin: center
    animation-delay: 3.5s

    @keyframes bombilloTres
        0% 
            transform: translateY(0)
            fill: $primario
        
        1%
            transform: translateY(1vw)
        
        2%
            transform: translateY(-1vw)
        
        3%
            transform: translateY(1vw)
        
        4%
            transform: translateY(-1vw)
        
        5%
            transform: translateY(1vw)
        
        6%
            transform: translateY(-1vw)
        
        7%
            transform: translateY(1vw)
        
        8%
            transform: translateY(-1vw)
        
        15%
            fill: $primario
        
        17%
            fill: $secundario
        
        19%
            fill: $primario
        
        21%
            fill: $secundario
        
        23%
            fill: $primario

        25%
            fill: $secundario
        
        60%
            fill: $secundario
        
        100% 
            fill: $primario   

.onda-4
    animation: ondaCuatro 3s ease-in-out infinite
    transform-origin: left
    animation-delay: 1.5s

    @keyframes ondaCuatro
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.onda-5
    animation: ondaCinco 3s ease-in-out infinite
    transform-origin: center
    animation-delay: 1s

    @keyframes ondaCinco
        0%
            opacity: 1
        
        50%
            opacity: 0
        
        100%
            opacity: 1

.onda-6
    animation: ondaSeis 3s ease-in-out infinite
    transform-origin: center

    @keyframes ondaSeis
        0%
            opacity: 1
        
        50%
            opacity: 0
        
        100%
            opacity: 1

.billete-2
    animation: billeteDos 10s ease-in-out infinite
    transform-origin: center


    @keyframes billeteDos
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(2%) rotateZ(0deg) rotateY(0deg) rotateX(20deg)
        
        50% 
            transform: translateX(3%) rotateZ(0deg) rotateY(0deg) rotateX(30deg)
        
        75% 
            transform: translateX(4%) rotateZ(0deg) rotateY(0deg) rotateX(40deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.billete-3
    animation: billeteTres 10s ease-in-out infinite
    transform-origin: center

    @keyframes billeteTres
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(1%) rotateZ(0deg) rotateY(30deg) rotateX(0deg)
        
        50% 
            transform: translateX(2%) rotateZ(0deg) rotateY(20deg) rotateX(0deg)
        
        75% 
            transform: translateX(3%) rotateZ(10deg) rotateY(0deg) rotateX(0deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.billete-4
    animation: billeteCuatro 18s ease-in-out infinite
    transform-origin: center

    @keyframes billeteCuatro
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(2%) rotateZ(5deg) rotateY(30deg) rotateX(0deg)
        
        50% 
            transform: translateX(3%) rotateZ(3deg) rotateY(20deg) rotateX(0deg)
        
        75% 
            transform: translateX(4%) rotateZ(0deg) rotateY(10deg) rotateX(0deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)