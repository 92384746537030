.features-general
    font-size: 1em
    width: 100%
    margin-bottom: 10vw
    >.contenedor-features
        display: flex
        font-size: 1em
        width: 100%
        column-gap: 2vw
        margin-left: 3em
        margin-top: 2em
        justify-content: space-between
        @media screen and ( max-width: 800px )
            flex-direction: column-reverse
            margin-left: 0
            margin-top: 0

        >.contenedor-celular-features
            font-size: 1em
            +border-top-right-radius(75px)
            width: 30%
            display: flex
            flex-direction: column
            align-items: center
            transform: rotate(-8deg)

            @media screen and ( max-width: 800px )
                width: 100%
                padding-left: 0

                margin: 5em 0 2em

            >.contenedor-imagenes-celulares-features
                position: relative
                height: 33em
                margin-top: 8em
                @media screen and ( max-width: 800px )
                    margin-top: 2em
                    height: 15em

                >.celular-features
                    width: 17em
                    left: 50%
                    transform: translate(-50%, 0)
                    position: absolute
                    transition: all 1s ease
                    @media screen and ( max-width: 800px )
                        width: 10em

            >.logo-features
                width: 12em
                margin: 1em 0 3em

        >.contenedor-tarjetas-features
            font-size: 1em
            width: 65%
            display: flex
            flex-direction: column
            // align-items: center
            @media screen and ( max-width: 800px )
                width: 100%
                align-items: center

            >.grilla-tarjetas-features
                box-sizing: border-box
                display: grid
                grid-template-columns: repeat(auto-fill,minmax(21em,1fr))
                row-gap: 6vw
                margin: 2vw 0 0em 0em
                width: 95%
                justify-items: center
                @import ./tarjeta-feature
                @media screen and ( max-width: 800px )
                    margin-bottom: 0em
                    margin-top: 3em
                    width: 90%
