.contenedor-tarjeta-feature
    font-size: 1em
    +border-radius(40px)
    +border-top-left-radius(5px)
    display: flex
    width: 25vw
    max-width: 25em
    min-width: 20em
    height: 18em
    box-sizing: border-box
    flex-direction: column
    @media screen and (max-width: 800px)
        align-items: center
    >.contenedor-imagen-feature
        width: 40%
        text-align: left
        @media screen and (max-width: 800px)
            text-align: center
        >img
            width: 5em
            height: 5em
            margin-left: 0.5em
        @media screen and (max-width: 800px)
            margin-left: 0
    >.contenedor-texto-tarjeta-feature
        padding-top: 1em
        font-size: 1em
        margin: 0.7em 2em 2em 0em
        display: flex
        flex-direction: column
        width: 90%
        box-sizing: border-box
        @media screen and (max-width: 800px)
            align-items: center
            text-align: center
            width: 100%
            margin-right: 0
        >.titulo-tarjeta-feature
            letter-spacing: 2px
            color: $secundario
            margin-bottom: 0.7em
            font-size: 1.4em
            text-transform: uppercase
        >.texto-tarjeta-feature
            font-size: 1.1em
            font-weight: 300
            letter-spacing: 1px
            color: $primario
            margin-bottom: 0.6em
