.contenedor-globo
    width: 100%
    height: 100%
    position: relative

    img
        width: 100%
        height: 100%
        object-fit: contain

    .girada
        +transform(rotateY(180deg) translateX(-0.3em))

    .globo
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0