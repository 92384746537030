.cls-movil-1
    fill:none
.cls-movil-2
    clip-path:url(#clip-path)
.cls-movil-3
    fill:#cae0df
.cls-movil-4
    fill:#c01351
.cls-movil-5
    fill:#3c5972
.cls-movil-6
    fill:#c01250
.cls-movil-7
    fill:#c11150
.cls-movil-8
    fill:#77b6b5
.cls-movil-9
    fill:#425c75

.fondo-animacion-movil
    width: 100%
    height: 100%



.billete-6
    animation: billeteSeis 8s ease-in-out infinite
    transform-origin: center


    @keyframes billeteSeis
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(1%) rotateZ(0deg) rotateY(0deg) rotateX(10deg)
        
        50% 
            transform: translateX(2%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        75% 
            transform: translateX(3%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.billete-7
    animation: billeteSiete 10s ease-in-out infinite
    transform-origin: center


    @keyframes billeteSiete
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(2%) rotateZ(0deg) rotateY(0deg) rotateX(5deg)
        
        50% 
            transform: translateX(3%) rotateZ(0deg) rotateY(0deg) rotateX(10deg)
        
        75% 
            transform: translateX(4%) rotateZ(0deg) rotateY(0deg) rotateX(15deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.billete-8
    animation: billeteOcho 12s ease-in-out infinite
    transform-origin: center


    @keyframes billeteOcho
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(3%) rotateZ(0deg) rotateY(0deg) rotateX(5deg)
        
        50% 
            transform: translateX(4%) rotateZ(10deg) rotateY(0deg) rotateX(10deg)
        
        75% 
            transform: translateX(5%) rotateZ(0deg) rotateY(0deg) rotateX(15deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)