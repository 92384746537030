.contenedor-seccion-mot
    width: 100%
    overflow: hidden
    margin: 2em 0

    @media screen and ( max-width: 1500px )
        font-size: 1em
        
    @media screen and ( min-width: 1800px )
        font-size: 1.25vw

    .contenedor-texto-carrusel
        width: 22.5vw
        min-width: 22.5vw
        height: 20em
        margin: 5vw 2.1vw
        border-radius: 0.23em 3em 3em 3em
        color: $primario

        &:hover

            .textos
                background: $primario
                color: white

        h2
            font-size: 1.4em
            color: $secundario
            padding: 1em 12% 0em 12%

        p 
            padding: 0em 12% 1em 12%

        .textos
            font-size: 0.8em
            margin-top: 3em
            width: 100%
            height: 50%
            border-radius: 0.5em 2.2em 2.2em 2.2em
        
        .personas
            width: 40%
            height: 8.75vw
            border-radius: 100%
            border: solid 0.2em $secundario 
            overflow: hidden
            object-fit: cover
            background-color: $secundario 

        @media screen and ( max-width: 800px )
            width: 70vw
            min-width: 70vw
            height: 12em
            margin: 2em 5vw 0
            font-size: 1.7em

            h2
                font-size: 1.5em

            .textos
                font-size: 0.5em
                margin-top: 1em
                color: white
                background: $primario

            .personas
                width: 5em
                height: 5em

    .boton-izquierda
        margin-top: 12.5em
        left: 4.5%
        position: absolute
        z-index: 2
        transform: rotate(90deg)

        button
            border: none
            background: none

            img
                width: 2em
                height: 2em
                margin: 1em
                cursor: pointer

        @media screen and ( max-width: 800px )
            margin-top: 8em
            left: 3%

            button
                img
                    width: 1.5em
                    height: 1.5em
                    margin: 0.5em

        @media screen and ( min-width: 1800px )
            button
                img
                    width: 2vw
                    height: 2vw

    .boton-derecha
        display: flex
        justify-content: flex-end
        align-items: flex-end
        margin-top: 12.5em
        position: absolute
        z-index: 2
        right: 4.5%
        transform: rotate(-90deg)

        button
            border: none
            background: none

            img
                width: 2em
                height: 2em
                margin: 1em
                cursor: pointer

        @media screen and ( max-width: 800px )
            margin-top: 8em 
            right: 3%

            button
                img
                    width: 1.5em
                    height: 1.5em
                    margin: 0.5em

        @media screen and ( min-width: 1800px )
            button
                img
                    width: 2vw
                    height: 2vw

    .contenedor-ocultar
        width: 80vw
        margin: 0 10%
        overflow: hidden 

        @media screen and ( max-width: 800px )
            width: 80vw
            margin: 0 10vw

    .carrusel-textos
        transition: 1s ease-in-out
    
    @media screen and ( max-width: 800px )
        .contenedor-seccion-mot
            width: 80vw