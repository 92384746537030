.footer {
    margin-top: auto;
    display: flex;
    justify-content: center;
    background: $secundario 0% 0% no-repeat padding-box;
    @include border-radius(50px 5px 0px 0px);
    padding: 2em 0 1.25em;

    .content{
        display: flex;
        justify-content: space-between;
        width: 90%;

        .contact{
            padding: 0 1em;
            display: flex;
            flex-direction: column;
            color: white;

            .titulo {
                letter-spacing: 2.5px;
            }

            .contact-grid {
                display: grid;
                margin-top: 1em;
                grid-template-columns: 0.5fr 1fr;
                grid-template-rows: 1fr 1fr;
                row-gap: 0.5em;
                align-content: center;
                justify-content: center;
            }

            .terminos-condiciones {
                color: white;
                font-weight: bold;
                margin-top: 5px;
            }
        }

        .social {
            display: flex;
            flex-direction: column;
            color: white;

            .find {
                letter-spacing: 2.5px;
                margin-bottom: 0.5em;
            }

            .social-media {
                display: flex;

                .red-social{
                    width: 3.5rem;
                    cursor: pointer;
                    margin-right: 1.5em;
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .footer{
        @include border-radius(15px 5px 0px 0px);
        .content{
            flex-direction: column;
            .social {
                margin-top: 1em;
                padding: 0 1em;
            }
        }
    } 
}