.encabezado-about
    font-size: 16px
    background-color: rgba(0, 0, 0, 1)
    background-image: url("../../../../assets/ciudad_about.jpg")
    background-size: cover
    background-position: 100% 70%
    padding: 12em 0 7.5em
    +border-bottom-left-radius(6em)
    position: relative
    @media screen and (max-width: 500px)
        font-size: 3.2vw
    >.texto-encabezado-about
        color: white
        text-transform: uppercase
        font-size: 2.5em
        width: 50%
        text-align: center
        letter-spacing: 3px
        z-index: 1
.capa-roja
    background-color: rgba(132,44,64,0.5)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    font-size: 1em
    +border-bottom-left-radius(6em)