.contenedor-decripcion-about
    font-size: 1em
    width: 100%
    margin: 5em 0 12vw
    @media screen and ( max-width: 1000px )
        margin-bottom: 3em
        
    >.elementos-descripcion-about
        width: 100%
        font-size: 1vw
        position: relative
        @media screen and ( max-width: 1000px )
            flex-direction: column
            justify-content: center
            align-items: center
        
        >.contenedor-hover-celular-descripcion
            position: absolute
            font-size: 1em
            width: 100%
            height: 100%
            left: 0
            top: 0
            @media screen and ( max-width: 1000px )
                position: relative

            >.contenedor-celular-descripcion
                font-size: 1em
                width: 100%
                height: 100%
                padding-right: 150em
                transition: all 0.5s linear
                margin-bottom: 5em
                padding-top: 4em
                @media screen and (max-width: 1000px)
                    padding-top: 0
                    margin-top: 6em
                    margin-bottom: 0
                    display: flex
                    justify-content: center
                    padding-left: 0 !important
                >img
                    font-size: 1em
                    width: 20vw
                    transform: rotate(30deg)
                    object-fit: contain
                    @media screen and ( max-width: 1000px )
                        width: 30vw
                        transform: rotate(30deg)
                        

        >.contenedor-video-texto-descripcion
            width: 50%
            font-size: 1em
            margin-right: 10vw
            z-index: 1
            // margin-top: 5vw
            @media screen and ( max-width: 1000px )
                width: 100%
                align-items: center
                margin-right: 0
                font-size: 2vw
                margin-top: 4em
            >.contenedor-video
                width: 41em
                height: 22em
                margin-bottom: 3em
            >.titulo-descripcion-about
                width: 17.6em
                font-size: 2.3em
                margin-bottom: 0.7em
                @media screen and ( max-width: 1000px )
                    text-align: left
            >.texto-descripcion-about
                letter-spacing: 3px
                font-size: 0.95em
                width: 44em
                line-height: 1.5em
                z-index: -1
                @media screen and ( max-width: 1000px )
                    text-align: left
                    font-size: 20px
                    width:80%
                @media screen and ( max-width: 500px )
                    text-align: left
                    font-size: 3.8vw
                    width:80%
                    

