.contenedor-test-yourself
    margin-bottom: 5em
    
    @media screen and ( min-width: 1500px )
        font-size: 1.2em

    @media screen and ( min-width: 1800px )
        font-size: 1.3vw

    .contenedor-test
        width: 100%
        flex-direction: row

        .imagen
            width: 37%
            margin-left: 5%

            .imagen-test
                width: 100%
                height: 100%

        .textos
            margin-top: 9em
            width: 46%
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start

            h2
                color: $secundario
                margin-left: 18%
                font-size: 1.5em

            p 
                margin: 2.5em 18%
                font-size: 1em

        .link
            width: 15em
            height: 1.8em
            padding: 2%
            margin-left: 18%
            border-radius: 0.85em 0.23em 0.85em 0.85em
            cursor: pointer
            text-decoration-line: none

            a
                font-size: 1em

        @media screen and ( max-width: 850px )
            flex-direction: column

            .textos
                margin-top: 2em
                width: 100%

                h2
                    margin: 0em 1.3em
                    font-size: 1.5em

                p 
                    margin: 1em 1.5em
                    font-size: 1.3em

            .link
                width: 15em
                height: 1.3em
                margin-left: 2em
                border-radius: 0.85em 0.23em 0.85em 0.85em
                
                a
                    font-size: 0.9em

            .imagen
                width: 80%
                margin: 1.5em 12%