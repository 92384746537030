.contenedor-categorias
    display: flex
    flex-direction: row
    margin-bottom: 5em

    @media screen and ( max-width: 850px )
        width: 100%
        display: flex
        flex-direction: column
        margin-bottom: 0

    @media screen and ( min-width: 1500px )
        font-size: 1.4em

    @media screen and ( min-width: 1800px )
        font-size: 1.3vw

    .textos
        margin-top: 9em
        width: 50%
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: flex-start

        h2
            color: $secundario
            margin-left: 33%
            font-size: 1.7em

        p 
            margin: 2.5em 12%
            font-size: 0.8em
            text-align: end

        @media screen and ( max-width: 850px )
            margin-top: 0em
            width: 100%

            h2
                margin-left: 12.5%
                font-size: 1.3em

            p 
                margin: 1em 12.5%
                font-size: 1em
                text-align: start

        @media screen and ( min-width: 1500px )
            margin-top: 9em
            width: 50%

            h2
                margin-left: 28%

            p 
                margin: 2.5em 7.5%

        @media screen and ( min-width: 1800px )

            h2
                margin-left: 33%

            p 
                margin: 2.5em 10.5%
            
    .contenedor-imagenes
        width: 50%
        height: 22em
        position: relative
        margin-top: 3em
        
        img
            position: absolute
            object-fit: contain
            width: 100%
            height: 100%
            transition: 1s ease-in-out
        
        @media screen and ( max-width: 850px )
            width: 100%
            height: 18em
            position: relative
            margin: 2em 0

        @media screen and ( min-width: 1500px )
            margin-left: 2%