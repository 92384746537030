.color-ilustracion-test-1
    fill:#cae0df

.color-ilustracion-test-2
    fill:#3d5972

.color-ilustracion-test-3
    fill:#edbe9e

.color-ilustracion-test-4
    fill:#75b6b5

.color-ilustracion-test-5
    fill:#c01250

.color-ilustracion-test-6
    fill:#c01351

.imagen-test
    width: 50%

.sol-1
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 3s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1
            
.sol-2
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 4s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.sol-3
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 5s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.sol-4
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 6s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.sol-5
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 7s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.sol-6
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 8s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.sol-7
    animation: sol 2.8s ease-in-out infinite
    transform-origin: left
    animation-delay: 9s

    @keyframes sol
        0%
            opacity: 1
        
        50%
            opacity: 0

        100%
            opacity: 1

.hoja-1
    animation: hojaUno 5s ease-in-out infinite
    transform-origin: center

    @keyframes hojaUno
        0% 
            transform: translateX(-1.2%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        50% 
            transform: translateX(-1.2%) rotateZ(0.5deg) rotateY(4deg) rotateX(20deg)
        100% 
            transform: translateX(-1.2%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-2
    animation: hojaDos 5s ease-in-out infinite
    transform-origin: center

    @keyframes hojaDos
        0% 
            transform: translateX(-1.2%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1.2%) rotateZ(3deg) rotateY(0deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1.2%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-3
    animation: hojaTres 5s ease-in-out infinite
    transform-origin: center

    @keyframes hojaTres
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(8deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-4
    animation: hojaCuatro 5s ease-in-out infinite
    transform-origin: center

    @keyframes hojaCuatro
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(8deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-5
    animation: hojaCinco 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaCinco
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(20deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-6
    animation: hojaSeis 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaSeis
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(24deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-7
    animation: hojaSiete 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaSiete
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(22deg) rotateX(2deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-8
    animation: hojaOcho 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaOcho
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(15deg) rotateX(0deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-9
    animation: hojaNueve 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaNueve
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(8deg) rotateX(10deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)

.hoja-10
    animation: hojaDiez 6s ease-in-out infinite
    transform-origin: center

    @keyframes hojaDiez
        0% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(-1%) rotateZ(1deg) rotateY(13deg) rotateX(5deg)
        
        100% 
            transform: translateX(-1%) rotateZ(0deg) rotateY(0deg) rotateX(0deg)