.color-movil-1
    fill:#c11150
.color-movil-2
    fill:#ebab7f
.color-movil-3
    fill:#76b6b5
.color-movil-4
    fill:#3f5c74
.color-movil-5
    fill:#92b4b8
.color-movil-6
    fill:#3d5972
.color-movil-7
    fill:#82bcbb
.color-movil-8
    fill:#c01250
.color-movil-9
    fill:#75b6b5
.color-movil-10
    fill:#edbe9e
.color-movil-11
    fill:#77b6b5
.color-movil-12
    fill:#c01351
.color-movil-13
    fill:#3c5972
.color-movil-14
    fill:#ecbe9f
.color-movil-15
    fill:#425c75

.animacion-personajes-movil
    width: 100%
    position: absolute

.billete-5
    animation: billeteCinco 12s ease-in-out infinite
    transform-origin: center


    @keyframes billeteCinco
        0% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)
        
        25%
            transform: translateX(3%) rotateZ(5deg) rotateY(0deg) rotateX(0deg)
        
        50% 
            transform: translateX(4%) rotateZ(10deg) rotateY(0deg) rotateX(0deg)
        
        75% 
            transform: translateX(5%) rotateZ(15deg) rotateY(0deg) rotateX(0deg)
        
        100% 
            transform: translateX(0) rotateZ(0deg) rotateY(0deg) rotateX(0deg)