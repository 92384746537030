.fondo-dialogo-generico
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1300
    position: fixed
    background-color: rgba(0, 0, 0, 0.4)
    -webkit-tap-highlight-color: transparent
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

    >.fondo-dialogo-rc
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 1301
        position: absolute
        -webkit-tap-highlight-color: transparent
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

    >.contenedor-dialogo
        display: flex
        flex-direction: column
        align-items: center
        width: 27em
        padding: 2em 2em
        background-color: $primario
        z-index: 1302
        @include border-radius(30px)
        font-size: 16px
        @media screen and ( max-width: 500px )
            width: 80%
            font-size: 3vw

        >.titulo-dialogo
            text-align: center
            font-size: 1.8em
            font-weight: bolder
            color: $secundario
            margin-bottom: 1em

        >.descripcion-dialogo
            text-align: center
            font-size: 1.3em
            color: $secundario
            width: 90%
            margin: auto
            margin-bottom: 1em

        >.contenedor-botones-dialogo
            display: flex
            width: 80%
            justify-content: space-evenly
            font-size: 1em

            >.boton-dialogo
                border-radius: 30px
                padding: 0.5em 1em
                background-color: $secundario
                color: $primario
                border: none
                font-weight: 500
                margin: 0.5em 0
                outline: none
                cursor: pointer
                font-size: 1.1em
                width: 7em

