.contenedor-preguntas-about
    font-size: 1em
    .grilla-preguntas-about
        align-items: flex-start
        margin-top: 4.4vw
        margin-bottom: 4vw
        max-width: 95%
        width: 80em
        font-size: 1em
        display: flex
        flex-wrap: wrap
        grid-row-gap: 2em
        column-gap: 0.2em
        justify-content: space-evenly
        @import './tarjeta-pregunta.scss'

        @media (max-width: 768px)
            margin: 5em 0