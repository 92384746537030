.contenedor-tarjeta-pregunta-about {
  //   font-size: 1em;
  width: 24em;
  margin-bottom: 1vw;
  box-shadow: 2px 5px 10px 6px rgba(25, 27, 39, 0.08);
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10rem 0 0;
  max-width: 30rem;
  overflow: hidden;
  transition: transform 500ms ease;
  height: 250px;
  cursor: pointer;

  &:hover,
  :focus-within {
    transform: scale(1.05);
  }
}

.contenedor-tarjeta-pregunta-about:nth-child(1) {
  background-image: url('../../../../assets/image-1.png');
}
.contenedor-tarjeta-pregunta-about:nth-child(2) {
  background-image: url('../../../../assets/image-2.png');
}
.contenedor-tarjeta-pregunta-about:nth-child(3) {
  background-image: url('../../../../assets/image-3.png');
  background-position: left;
}
.contenedor-fondo {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1.5rem;
  background: linear-gradient(
    rgba(0, 0, 0, 0.02) 0%,
    rgba(0, 0, 0, 0.2) 10%,
    rgba(0, 0, 0, 0.3) 30%,
    rgba(0, 0, 0, 0.5) 60%,
    rgba(0, 0, 0, 0.7) 100%
  );
  margin-top: 21em;
  transition: transform 500ms ease;
  color: #fff;

  // height: 100px

  &:hover {
    transform: translateY(-60%);
  }
  .titulo-tarjeta-pregunta {
    position: relative;
    width: max-content;
    font-size: 1.4em;
    width: 11em;
    align-self: start;
    text-align: left;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 1rem;
    // color: #f16f8b;
    color: #fff;
    letter-spacing: 0.5px;
  }

  .titulo-tarjet-pregunta:nth-child(1) {
    background-color: #181a27;
  }

  .titulo-tarjeta-pregunta::after {
    content: '';
    position: absolute;
    height: 4px;
    left: -1.6rem;
    top: 3.5rem;
    transform: scaleX(0);
    width: calc(100% - 1rem);
    background: $secundario;
    transform-origin: left;
    transition: transform 500ms ease;
    transition-delay: 600ms;
  }
}

.contenedor-tarjeta-pregunta-about:hover .titulo-tarjeta-pregunta::after,
.contenedor-tarjeta-pregunta-about:focus-within
  .titulo-tarjeta-pregunta::after {
  transform: scaleX(1);
}

.texto-tarjeta-pregunta {
  font-size: 0.9em;
  width: 22em;
  text-align: left;
  color: #181a27;
  letter-spacing: 2px;
  margin-left: 2em;
  align-self: start;
}

.contenido-tarjeta {
  display: flex;
  flex-direction: column;
  margin: 0.7rem 0;

  h3.subtitulo-card {
    padding-bottom: -1rem;
  }
}

.contenido-tarjeta-btn {
  margin-bottom: 1em;
}

ol {
  margin-top: 1.5rem;
  //   margin-bottom: 1rem;
}
li {
  margin-top: 0.3em;
  line-height: 1.4;
  margin-left: 1rem;
}
a {
  margin-top: 1em;
  align-self: center;
  padding: 0.6em 2em;
  text-align: center;
  border-radius: 20px;
  background-color: $secundario;
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 3em;
}

p {
  font-weight: 500;
  margin-top: 1rem;
  line-height: 1.4;
  margin-bottom: 2em;
}

span {
  margin-top: 1rem;
  font-weight: 500;
  line-height: 1.4;
  color: $secundario;
}

@media (max-width: 768px) {
  .contenedor-tarjeta-pregunta-about {
    height: 200px;

    .contenedor-fondo {
      margin-top: 20em;
      // height: 250px;
      padding-bottom: 1em;

      &:hover {
        transform: translateY(-55%);
      }
      .titulo-tarjeta-pregunta {
        width: 80%;
        &::after {
          top: 2.8em;
        }
      }
    }
  }

  .contenedor-tarjeta-pregunta-about:nth-child(1) {
    background-position: right;
  }
  .contenedor-tarjeta-pregunta-about:nth-child(3) {
    background-position: inherit;
  }
}
